module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.alj3.clients.lemonhq.io/graphql","type":{"Quickreads":{"limit":0},"Businesssector":{"limit":0},"Inthenews":{"limit":0},"Perspective":{"limit":0},"Pressrelease":{"limit":0},"J75Journey":{"limit":0},"J75story":{"limit":0},"Ourpeople":{"limit":0},"Spotlightbyfadyjameel":{"limit":0},"Innerpagecarousel":{"limit":0},"MenuItem":{"limit":0},"ContentType":{"limit":0},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":50,"timeout":12000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","requestConcurrency":15,"previewRequestConcurrency":5},"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"html":{"generateWebpImages":false,"placeholderType":"none","useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateAvifImages":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"ALJ 3.0","type":"website","locale":"en_US","description":"","images":[{"url":"https://media.jameelmotors.com/wp-content/uploads/2023/12/metaimage.jpeg","alt":"ALJ 3.0"}],"site_name":"ALJ 3.0"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"hotjar":{"hjid":"3305184","cookieName":"gatsby-gdpr-hotjar"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
