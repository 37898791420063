exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-signed-a-comprehensive-collaboration-agreement-with-kurume-university-js": () => import("./../../../src/pages/news/signed-a-comprehensive-collaboration-agreement-with-kurume-university.js" /* webpackChunkName: "component---src-pages-news-signed-a-comprehensive-collaboration-agreement-with-kurume-university-js" */),
  "component---src-pages-news-signed-a-memorandum-of-understanding-with-the-garage-js": () => import("./../../../src/pages/news/signed-a-memorandum-of-understanding-with-the-garage.js" /* webpackChunkName: "component---src-pages-news-signed-a-memorandum-of-understanding-with-the-garage-js" */),
  "component---src-pages-news-signed-agency-agreement-with-melody-co-ltd-js": () => import("./../../../src/pages/news/signed-agency-agreement-with-melody-co-ltd.js" /* webpackChunkName: "component---src-pages-news-signed-agency-agreement-with-melody-co-ltd-js" */),
  "component---src-pages-news-signed-mou-with-l-pixel-js": () => import("./../../../src/pages/news/signed-mou-with-l-pixel.js" /* webpackChunkName: "component---src-pages-news-signed-mou-with-l-pixel-js" */),
  "component---src-pages-news-strategic-partnership-concluded-with-yagami-seisakusho-js": () => import("./../../../src/pages/news/strategic-partnership-concluded-with-yagami-seisakusho.js" /* webpackChunkName: "component---src-pages-news-strategic-partnership-concluded-with-yagami-seisakusho-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-japan-press-release-detail-js": () => import("./../../../src/templates/japanPressReleaseDetail.js" /* webpackChunkName: "component---src-templates-japan-press-release-detail-js" */),
  "component---src-templates-japan-press-release-js": () => import("./../../../src/templates/japanPressRelease.js" /* webpackChunkName: "component---src-templates-japan-press-release-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

